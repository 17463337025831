var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calls"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.calls,"no-data-text":_vm.noData,"items-per-page":10,"footer-props":{
      disableItemsPerPage: false,
      itemsPerPageOptions: [10, 25, 50, 100],
      itemsPerPageText: 'Llamados por página',
    },"show-expand":"","single-expand":"","loading":_vm.loading,"loading-text":"Buscando llamados ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"elevation":"1","height":_vm.responsive_height}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"inset":"","label":"Todas las unidades","color":"green"},model:{value:(_vm.all_building),callback:function ($$v) {_vm.all_building=$$v},expression:"all_building"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.ranges,"label":"Período","item-text":"name","item-value":"id","color":_vm.whiteLabel.colors.primary,"item-color":_vm.whiteLabel.colors.primary,"filled":"","dense":""},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1),_c('v-select',{attrs:{"items":_vm.floors,"label":"Planta","item-text":"name","item-value":"id","color":_vm.whiteLabel.colors.primary,"item-color":_vm.whiteLabel.colors.primary,"filled":"","dense":"","disabled":_vm.all_building},model:{value:(_vm.floor),callback:function ($$v) {_vm.floor=$$v},expression:"floor"}}),_c('v-select',{attrs:{"items":_vm.units,"label":"Unidad","item-text":"name","item-value":"id","color":_vm.whiteLabel.colors.primary,"item-color":_vm.whiteLabel.colors.primary,"filled":"","dense":"","disabled":_vm.all_building},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1)]}}])})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.duration > 0)?_c('div',[_vm._v(" Duración: "+_vm._s(item.duration)+" segundos ")]):_vm._e(),(item.answered_by)?_c('div',[_vm._v(" Atendido por: "+_vm._s(item.answered_by)+" ")]):_vm._e(),(item.image)?_c('div',[_c('img',{attrs:{"src":item.image}})]):_vm._e()])]}},{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('img',{staticClass:"thumbnail",attrs:{"src":item.image}})]}},{key:"item.datetime",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.datetime).toLocaleString("es-AR")))])]}},{key:"item.mode",fn:function(ref){
    var item = ref.item;
return [(item.mode && item.duration > 0)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-cellphone-wireless")])]}}],null,true)},[_c('span',[_vm._v("Atendida desde dispositivo")])])],1):_vm._e(),(item.mode && item.duration == 0)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-cellphone-wireless")])]}}],null,true)},[_c('span',[_vm._v("Rechazada desde dispositivo")])])],1):_vm._e(),(!item.mode && item.duration > 0)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-phone")])]}}],null,true)},[_c('span',[_vm._v("Atendida desde teléfono")])])],1):_vm._e(),(!item.mode && item.duration == 0)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-phone")])]}}],null,true)},[_c('span',[_vm._v("Rechazada desde telêfono")])])],1):_vm._e()]}}])}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }