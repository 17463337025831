<template>
  <div class="helps">
    <v-data-table
      :headers="headers"
      :items="helps"
      :no-data-text="noData"
      :items-per-page="10"
      :footer-props="{
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 25, 50, 100],
        itemsPerPageText: 'Accesos por página',
      }"
      class="elevation-1"
      show-expand
      single-expand
      :loading="loading"
      loading-text="Buscando S.O.S. ..."
    >
      <template v-slot:top>
        <v-toolbar elevation="1" :height="responsive_height">
          <v-dialog max-width="500px">
            <template v-slot:activator="{}">
              <v-container>
                <v-row align="center">
                  <v-col cols="12" sm="6" class="d-flex">
                    <v-switch
                      v-model="all_building"
                      inset
                      label="Todas las unidades"
                      color="green"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex">
                    <v-select
                      v-model="range"
                      :items="ranges"
                      label="Período"
                      item-text="name"
                      item-value="id"
                      :color="whiteLabel.colors.primary"
                      :item-color="whiteLabel.colors.primary"
                      filled
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-select
                  v-model="floor"
                  :items="floors"
                  label="Planta"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  filled
                  dense
                  :disabled="all_building"
                ></v-select>
                <v-select
                  v-model="unit"
                  :items="units"
                  label="Unidad"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  filled
                  dense
                  :disabled="all_building"
                ></v-select>
              </v-container>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div v-if="item.type == 1">S.O.S.: Policia</div>
          <div v-if="item.type == 2">S.O.S.: Ambulancia</div>
          <div v-if="item.type == 3">S.O.S.: Bomberos</div>
          <div v-if="item.type == 4">S.O.S.: Antientradera</div>
          <GmapMap
            v-if="item.location"
            ref="map"
            :center="item.location"
            :zoom="17"
            map-type-id="terrain"
            style="width: 100%; height: 300px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false,
              styles: [
                {
                  featureType: 'poi',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  featureType: 'transit',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
              ],
            }"
          >
            <GmapMarker
              :position="item.location"
              :clickable="false"
              :draggable="false"
            />
          </GmapMap>
        </td>
      </template>

      <template v-slot:item.image="{ item }">
        <img :src="item.image" class="thumbnail" />
      </template>

      <template v-slot:item.datetime="{ item }">
        <span>{{ new Date(item.datetime).toLocaleString("es-AR") }}</span>
      </template>

      <template v-slot:item.type="{ item }">
        <span v-if="item.type == 1"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="black"
                >mdi-car-emergency</v-icon
              ></template
            >
            <span>Emergencia policial</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 2"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="blue"
                >mdi-ambulance</v-icon
              ></template
            >
            <span>Emergencia médica</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 3"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red"
                >mdi-fire-truck</v-icon
              ></template
            >
            <span>Emergencia de incendio</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 4"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red"
                >mdi-shield-key-outline</v-icon
              ></template
            >
            <span>Emergencia de anti-entradera</span>
          </v-tooltip></span
        >
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    floor: null,
    floors: [],
    unit: null,
    units: [],
    helps: [],
    all_contacts: [],
    all_units: [],
    all_floors: [],
    all_building: false,
    range: 30,
    ranges: [
      {
        name: "Ultimo mes",
        id: 30,
      },
      {
        name: "Ultimos 3 meses",
        id: 90,
      },
      {
        name: "Ultimos 6 meses",
        id: 180,
      },
      {
        name: "Ultimo año",
        id: 365,
      },
      {
        name: "Todo",
        id: null,
      },
    ],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    headers: [
      {
        text: "Planta y Unidad",
        align: "start",
        sortable: false,
        value: "unit",
      },
      { text: "Residente", align: "start", sortable: false, value: "contact" },
      { text: "Tipo", align: "start", sortable: false, value: "type" },
      { text: "Fecha", align: "start", sortable: false, value: "datetime" },
      { text: "Más", value: "data-table-expand", sortable: false },
    ],
    noData: "Seleccione planta y unidad",
  }),

  props: ["initial_floor", "initial_unit"],

  created() {
    this.getAllFloors();
    this.getAllContacts();
    this.getAllUnits();
  },

  mounted() {
    this.getFloors();
    this.floor = this.initial_floor;
    this.unit = this.initial_unit;
  },

  computed: {
    ...mapGetters({
      user: "user",
      whiteLabel: "whiteLabel",
    }),

    responsive_height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 360;
        case "sm":
          return 320;
        case "md":
          return 300;
        case "lg":
          return 280;
      }
      return 360;
    },
  },

  watch: {
    floor() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando unidades ...";
      this.snackbar.color = "success";
      this.getUnits();
    },
    unit() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando accesos ...";
      this.snackbar.color = "success";
      this.getHelps();
    },
    all_building() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando accesos ...";
      this.snackbar.color = "success";
      this.getHelps();
    },
    range() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando accesos ...";
      this.snackbar.color = "success";
      this.getHelps();
    },
  },

  methods: {
    async getFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            this.floors = [];
            let floor_positions = null;

            db.doc("buildings/" + this.user.data.building)
              .get()
              .then((floor) => {
                floor_positions = floor.data().floor_positions;

                let unsorted_floors = [];
                floors.forEach((floor) => {
                  unsorted_floors.push(floor);
                });

                floor_positions.forEach((floor) => {
                  let f = unsorted_floors.find((f) => f.id === floor);
                  this.floors.push({ name: f.data().name, id: f.id });
                });
              });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getUnits() {
      try {
        if (this.initial_unit) {
          this.initial_unit = null;
        } else {
          this.unit = null;
          this.unit_mode = null;
        }
        await db
          .collection(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              this.floor +
              "/apartments"
          )
          .get()
          .then((units) => {
            this.units = [];
            let unit_positions = null;

            if (!units.empty) {
              db.doc(
                "buildings/" + this.user.data.building + "/floors/" + this.floor
              )
                .get()
                .then((floor) => {
                  unit_positions = floor.data().apartment_positions;
                  let unsorted_units = [];
                  units.forEach((unit) => {
                    unsorted_units.push(unit);
                  });
                  unit_positions.forEach((unit) => {
                    let u = unsorted_units.find((u) => u.id === unit);
                    this.units.push({
                      name: u.data().name,
                      id: u.id,
                      mode: u.data().mode,
                      alias: u.data().alias,
                      remarks: u.data().remarks,
                    });
                  });
                });
            } else {
              if (this.unit) {
                this.noData = "No existen unidades para esta planta";
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllContacts() {
      try {
        await db
          .collectionGroup("contacts")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((contacts) => {
            contacts.forEach((contact) => {
              this.all_contacts.push({
                id: contact.id,
                name: contact.data().name,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllUnits() {
      try {
        await db
          .collectionGroup("apartments")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((units) => {
            units.forEach((unit) => {
              this.all_units.push({
                id: unit.id,
                name: unit.data().name,
                mode: unit.data().mode,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            floors.forEach((floor) => {
              this.all_floors.push({
                id: floor.id,
                name: floor.data().name,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getHelps() {
      try {
        this.loading = true;
        this.helps = [];

        let query = db.collection(
          "buildings/" + this.user.data.building + "/helps"
        );
        if (!this.all_building) {
          query = query.where("apartment_id", "==", this.unit);
        }
        if (this.range) {
          var today = new Date();
          query = query.where(
            "datetime",
            ">=",
            new Date(today.setDate(today.getDate() - this.range))
          );
        }
        query = query.orderBy("datetime", "desc");
        await query.get().then((helps) => {
          if (!helps.empty) {
            helps.forEach((help) => {
              this.helps.push({
                id: help.id,
                unit: help.data().apartment,
                type: help.data().type,
                datetime: help.data().datetime.toDate(),
                contact: this.all_contacts.find(
                  (contact) => contact.id === help.data().user_id
                )
                  ? this.all_contacts.find(
                      (contact) => contact.id === help.data().user_id
                    ).name
                  : "",
                location: help.data().location
                  ? {
                      lat: help.data().location.latitude,
                      lng: help.data().location.longitude,
                    }
                  : null,
              });
            });
          } else {
            this.noData = "No existen registros para los filtros seleccionados";
          }
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-start img.thumbnail {
  border-radius: 50%;
  width: 50px;
}
</style>
