var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"helps"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.helps,"no-data-text":_vm.noData,"items-per-page":10,"footer-props":{
      disableItemsPerPage: false,
      itemsPerPageOptions: [10, 25, 50, 100],
      itemsPerPageText: 'Accesos por página',
    },"show-expand":"","single-expand":"","loading":_vm.loading,"loading-text":"Buscando S.O.S. ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"elevation":"1","height":_vm.responsive_height}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"inset":"","label":"Todas las unidades","color":"green"},model:{value:(_vm.all_building),callback:function ($$v) {_vm.all_building=$$v},expression:"all_building"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.ranges,"label":"Período","item-text":"name","item-value":"id","color":_vm.whiteLabel.colors.primary,"item-color":_vm.whiteLabel.colors.primary,"filled":"","dense":""},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1),_c('v-select',{attrs:{"items":_vm.floors,"label":"Planta","item-text":"name","item-value":"id","color":_vm.whiteLabel.colors.primary,"item-color":_vm.whiteLabel.colors.primary,"filled":"","dense":"","disabled":_vm.all_building},model:{value:(_vm.floor),callback:function ($$v) {_vm.floor=$$v},expression:"floor"}}),_c('v-select',{attrs:{"items":_vm.units,"label":"Unidad","item-text":"name","item-value":"id","color":_vm.whiteLabel.colors.primary,"item-color":_vm.whiteLabel.colors.primary,"filled":"","dense":"","disabled":_vm.all_building},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1)]}}])})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.type == 1)?_c('div',[_vm._v("S.O.S.: Policia")]):_vm._e(),(item.type == 2)?_c('div',[_vm._v("S.O.S.: Ambulancia")]):_vm._e(),(item.type == 3)?_c('div',[_vm._v("S.O.S.: Bomberos")]):_vm._e(),(item.type == 4)?_c('div',[_vm._v("S.O.S.: Antientradera")]):_vm._e(),(item.location)?_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"300px"},attrs:{"center":item.location,"zoom":17,"map-type-id":"terrain","options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            styles: [
              {
                featureType: 'poi',
                stylers: [
                  {
                    visibility: 'off',
                  } ],
              },
              {
                featureType: 'transit',
                stylers: [
                  {
                    visibility: 'off',
                  } ],
              } ],
          }}},[_c('GmapMarker',{attrs:{"position":item.location,"clickable":false,"draggable":false}})],1):_vm._e()],1)]}},{key:"item.image",fn:function(ref){
          var item = ref.item;
return [_c('img',{staticClass:"thumbnail",attrs:{"src":item.image}})]}},{key:"item.datetime",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.datetime).toLocaleString("es-AR")))])]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [(item.type == 1)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"black"}},'v-icon',attrs,false),on),[_vm._v("mdi-car-emergency")])]}}],null,true)},[_c('span',[_vm._v("Emergencia policial")])])],1):_vm._e(),(item.type == 2)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-ambulance")])]}}],null,true)},[_c('span',[_vm._v("Emergencia médica")])])],1):_vm._e(),(item.type == 3)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-fire-truck")])]}}],null,true)},[_c('span',[_vm._v("Emergencia de incendio")])])],1):_vm._e(),(item.type == 4)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-shield-key-outline")])]}}],null,true)},[_c('span',[_vm._v("Emergencia de anti-entradera")])])],1):_vm._e()]}}])}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }